import React from 'react';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Typography } from '@material-ui/core';
import { ExpansionList, ListElem } from '@aisales/react-uikit';
import {
  CONNECT,
  COUNTRY,
  CZECH_REPUBLIC,
  GERMANY,
  ITALY,
  KAZAKHSTAN,
  POLAND,
  RUSSIA,
  SPAIN,
  UKRAINE,
  USA,
} from '@main/i18n/aliases';
import { withTranslation } from 'react-i18next';
import questionMark from '../../question_mark.svg';
import { languages } from '../../constants';

const countries = [];

class VKGroups extends React.Component {
  constructor(props) {
    super(props);
    this.classes = props.classes;
    this.state = {
      selectedGroup: '',
      groupId: null,
    };

    this.t = props.t;

    countries.push(
      { code: 'CZ', name: this.t(CZECH_REPUBLIC) },
      { code: 'UA', name: this.t(UKRAINE) },
      { code: 'ES', name: this.t(SPAIN) },
      { code: 'PL', name: this.t(POLAND) },
      { code: 'US', name: this.t(USA) },
      { code: 'KZ', name: this.t(KAZAKHSTAN) },
      { code: 'RU', name: this.t(RUSSIA) },
      { code: 'IT', name: this.t(ITALY) },
      { code: 'DE', name: this.t(GERMANY) },
    );

    if (this.isEdit()) {
      let language = 6;
      for (let i = 0; i < languages.length; i++) {
        if (languages[i].code === this.props.platform.language) {
          language = i;
          break;
        }
      }
      let country = 6;
      for (let i = 0; i < countries.length; i++) {
        if (countries[i].code === this.props.platform.country) {
          country = i;
          break;
        }
      }
      this.state.groups = [
        {
          ...this.props.platform,
          photo_50: this.props.platform.photo || questionMark,
        },
      ];
      this.state.addGroup = {
        selectedGroup: 0,
        groupId: this.props.platform.id,
        lang: language,
        country,
        translate: this.props.platform.translate,
      };
    } else {
      this.state.groups = this.props.groups.items;
    }
  }

    handleChange = async (event) => {
      const list = JSON.parse(this.props.groups);
      event.preventDefault();
      event.stopPropagation();
      await this.setState({
        selectedGroup: event.target.getAttribute('value'),
        groupId: list.items[event.target.getAttribute('value')].id,
      });
    };

    getGroupToken = (i) => {
      const groupPermWindow = window.open(
        `https://oauth.vk.com/authorize?client_id=52582468&group_ids=${
          this.state.groups[i].id
        }&display=page&redirect_uri=${
          window.location.origin
        }/redirect/vk/group&scope=manage,messages,docs,photos,stories&response_type=token&v=5.92`,
        'Доступ к группе',
        'height=600,width=800',
      );
      const poptick = setInterval(() => {
        if (groupPermWindow.closed) {
          if (localStorage.getItem('group_access_token') !== null) {
            this.setState({
              addGroup: {
                selectedGroup: i,
                groupId: this.state.groups[i].id,
                lang: 6,
                country: 6,
                translate: false,
              },
            });
          }
          clearInterval(poptick);
        }
      }, 500);
    };

    isEdit = () => Boolean(this.props.platform);

    render() {
      const list = this.props.groups;
      return (
        <>
          {this.state.addGroup == null
                    && list !== null
                    && list.items.map((val, i) => (
                      <div className={this.classes.group} key={`group${i}`}>
                        <div className={this.classes.avatar}>
                          <img
                            src={val.photo_50}
                            width={40}
                            height={40}
                            style={{ borderRadius: '100%' }}
                          />
                        </div>
                        <div className={this.classes.name}>{val.name}</div>
                        <div className={this.classes.pid}>
                          <b>ID</b>
                          {' '}
                          {val.id}
                        </div>
                        <div className={this.classes.actions}>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                              this.getGroupToken(i);
                            }}
                            className={this.classes.connectButton}
                          >
                            {/* Подключить */}
                            Connect
                          </Button>
                        </div>
                      </div>
                    ))}
          {this.state.addGroup != null && (
          <div
            style={{
              textAlign: 'center',
              width: '200px',
              margin: 'auto',
              marginTop: '20px',
            }}
          >
            <div>
              <img
                src={
                                    this.state.groups[
                                      this.state.addGroup.selectedGroup
                                    ].photo_50
                                }
                width={50}
                height={50}
                style={{ borderRadius: '100%' }}
                alt="avatar"
              />
            </div>
            <div
              style={{
                borderBottom: '1px solid #0000001f',
                paddingBottom: '10px',
              }}
            >
              <h4 style={{ fontWeight: 6000 }}>
                {
                                    this.state.groups[
                                      this.state.addGroup.selectedGroup
                                    ].name
                                }
                {' '}
              </h4>
              {' '}
              <span style={{ color: '#a0a0a0' }}>
                ID:
                {' '}
                {this.state.addGroup.groupId}
              </span>
            </div>
            <div
              style={{
                textAlign: 'left',
                marginTop: '10px',
                paddingTop: '10px',
                width: '165px',
                margin: 'auto',
              }}
            >
              <div>
                <Typography
                  variant="caption"
                  style={{
                    display: 'block',
                    marginLeft: '5px',
                  }}
                >
                  {this.t(COUNTRY)}
                  :
                </Typography>
                <ExpansionList
                  closeOnClick
                  maxHeight="300px"
                  style={{
                    boxShadow: 'none',
                    maxWidth: '165px',
                    width: '165px',
                    fontFamily: 'Montserrat',
                    fontSize: '11px',
                    lineHeight: '13px',
                    color: 'black',
                    height: '40px',
                  }}
                  textValue={
                                        countries[this.state.addGroup.country]
                                          .name
                                    }
                >
                  {countries.map(
                    (country, index) => country.code
                                                !== countries[
                                                  this.state.addGroup.country
                                                ].code && (
                                                <ListElem
                                                  key={
                                                        `country${country.code}`
                                                    }
                                                  onClick={() => this.setState({
                                                    addGroup: {
                                                      ...this.state
                                                        .addGroup,
                                                      country: index,
                                                    },
                                                  })}
                                                >
                                                    {country.name}
                                                </ListElem>
                    ),
                  )}
                </ExpansionList>
              </div>
              <div style={{ marginTop: '10px' }}>
                <Typography
                  variant="caption"
                  style={{
                    display: 'block',
                    marginLeft: '5px',
                  }}
                >
                  Язык:
                </Typography>
                <ExpansionList
                  closeOnClick
                  maxHeight="300px"
                  style={{
                    boxShadow: 'none',
                    maxWidth: '165px',
                    width: '165px',
                    fontFamily: 'Montserrat',
                    fontSize: '11px',
                    lineHeight: '13px',
                    color: 'black',
                    height: '40px',
                  }}
                  textValue={
                                        languages[this.state.addGroup.lang].name
                                    }
                >
                  {languages.map(
                    (lang, index) => lang.code
                                                !== languages[
                                                  this.state.addGroup.lang
                                                ].code && (
                                                <ListElem
                                                  key={`lang${lang.code}`}
                                                  onClick={() => this.setState({
                                                    addGroup: {
                                                      ...this.state
                                                        .addGroup,
                                                      lang: index,
                                                    },
                                                  })}
                                                >
                                                    {lang.name}
                                                </ListElem>
                    ),
                  )}
                </ExpansionList>
              </div>
              <div>
                <FormControlLabel
                  style={{
                    margin: 0,
                    marginTop: '10px',
                    width: '100%',
                    justifyContent: 'space-between',
                    paddingLeft: '5px',
                  }}
                  control={(
                    <Checkbox
                      style={{
                        background: 'none',
                        border: 'none',
                        boxShadow: 'none',
                        opacity: 0.7,
                        padding: '0',
                        color: 'rgb(91, 127, 184)',
                      }}
                      color="primary"
                    />
                                      )}
                  onChange={() => this.setState({
                    addGroup: {
                      ...this.state.addGroup,
                      translate: !this.state.addGroup
                        .translate,
                    },
                  })}
                  checked={this.state.addGroup.translate}
                  labelPlacement="start"
                  label={(
                    <Typography
                      variant="caption"
                      style={{ userSelect: 'none' }}
                    >
                      Перевод
                    </Typography>
                                      )}
                />
              </div>
              <Button
                variant="contained"
                color="primary"
                style={{
                  marginTop: '10px',
                  backgroundColor: 'rgb(91, 127, 184)',
                  fontSize: '12px',
                  fontWeight: 600,
                }}
                onClick={() => {
                  if (this.isEdit()) {
                    this.props.saveGroup(
                      languages[this.state.addGroup.lang]
                        .code,
                      countries[
                        this.state.addGroup.country
                      ].code,
                      this.state.addGroup.translate,
                    );
                  } else {
                    this.props.addGroup(
                      this.state.groups[
                        this.state.addGroup
                          .selectedGroup
                      ].name,
                      String(this.state.addGroup.groupId),
                      languages[this.state.addGroup.lang]
                        .code,
                      countries[
                        this.state.addGroup.country
                      ].code,
                    );
                  }
                }}
                fullWidth
              >
                {this.isEdit() ? 'Сохранить' : this.t(CONNECT)}
              </Button>
              <Button
                variant="contained"
                style={{
                  marginTop: '10px',
                  fontSize: '12px',
                  color: 'white',
                }}
                onClick={() => {
                  if (this.isEdit()) {
                    this.props.onClose();
                  } else {
                    this.setState({ addGroup: null });
                  }
                }}
                fullWidth
              >
                Отмена
              </Button>
            </div>
          </div>
          )}
        </>
      );
    }
}

export default withTranslation()(VKGroups);
